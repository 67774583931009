<!--
 * @Author: chenlin 891635769@qq.com
 * @Date: 2022-08-04 18:26:03
 * @LastEditors: chenlin 891635769@qq.com
 * @LastEditTime: 2022-08-10 16:56:33
 * @FilePath: \_web_portal\src\views\onlineLearning\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="main pageMain">
    <Title></Title>
    <!--
    <div class="topBgc">
      <div class="head-box"></div>
    </div> -->
    <div class="content-box">
      <div class="top-content">
        <div class="breadcrumb-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>在线学习</el-breadcrumb-item>
            <el-breadcrumb-item>{{ course.title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="content-head-box">
          <div>{{ course.title }}</div>
        </div>
        <div class="title-head">
          <el-row :gutter="0">
            <el-col :span="8">
              <img class="img-top" :src="course.litimg" :onerror="$global.srcImgUrl" alt="" />
            </el-col>
            <el-col :span="16">
              <div class="top-explain">
                {{ course.subTitle }}
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="0" class="rows-price">
            <el-col :span="8">
              <div class="top-sign-up">{{ this.query.sumApplyNum ? this.query.sumApplyNum : 0 }}人已报名</div>
            </el-col>

            <el-col :span="5">
              <div class="price" v-if="course.free !== 'N'">
                <div>
                  <div class="top-price">
                    原价：<span style="text-decoration: line-through">{{
                      course.originalPrice
                    }}</span>
                  </div>
                </div>

                <div class="top-price-button-span">
                  <div>
                    <span>￥</span>
                    <span>{{
                      (
                        course.originalPrice -
                        (course.originalPrice * course.discountRate) / 100
                      ).toFixed(2)
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="price" v-else>
                <div class="top-price">
                    免费
                  </div>
              </div>
            </el-col>
            <el-col :span="10">
              <!--
              <div class="share-star">
                <img src="@/assets/onlineLearning/icon-share.png" />
                <span class="spanPostion">分享</span>
                <img style="margin-left: 20px" src="@/assets/onlineLearning/star-on.png" />
                <span class="spanPostion" style="">收藏</span>
              </div>
 -->
              <div class="top-price-button">
                <!--
                <el-button class="button-apply-audition" @click="registrationDetails(1)" plain>开始试听</el-button>
                 -->
                <el-button
                  v-if="!pay"
                  class="button-apply"
                  @click="registrationDetails(2)"
                  type="danger"
                  >立即报名</el-button
                >

                <el-button
                  v-if="pay"
                  class="button-apply-audition"
                  @click="learn(course.id)"
                  plain
                  >开始学习</el-button
                >
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- <div class="bottom-box">
          <div class="icon-title">
            <img src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/b88c61a2-9448-454f-abcd-e7d8ac52d3da.png" alt="" />
            <span>开课时间：{{course.createTime}}</span>
          </div>
          <div class="icon-title2">
            <img src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/cb4c0ef8-9cb6-42ec-bbb4-cf225e50c974.png" alt="" />
            <span>教学时长</span>
          </div>
          <div class="icon-title2">
            <img src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/a172bbd4-7bd2-4cce-bc52-bec2cbac7c58.png" alt="" />
            <span>学习投入</span>
          </div>
        </div> -->

        <div class="bottom-explain">
          <div>课程介绍</div>
          <div v-html="course.intro"></div>
        </div>
        <div class="title-show">课程章节</div>
        <div
          class="bottom-explain-cent"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div
            class="bottom-explain-cent-fist"
            @click="openChapters(item, index)"
          >
            <span>{{ index + 1 }}</span>
            <!--             <span>{{ item.chapter }}</span> -->
            <span>{{ item.title }}</span>
            <img
              v-if="index == openChapter"
              src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/9e0ff7f8-42a7-4b01-82d2-abaf875279af.png"
              alt=""
            />
            <img
              v-else
              src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/22fd2997-d52f-47d5-8636-50567f67e637.png"
              alt=""
            />
          </div>
          <el-divider></el-divider>
          <ul v-if="index == openChapter">
            <li
              v-for="(items, indexs) in item.data"
              :key="indexs"
              style="cursor: pointer"
            >
              {{ items.title }}
            </li>
          </ul>
        </div>
      </div>
      <pay :dialogVisible="dialogVisible" :payInfo="payInfo"></pay>
      <!-- <div class="row-cont"></div> -->
    </div>
    <Enroll
      v-if="outerVisible"
      ref="dialog"
      :visible.sync="outerVisible"
    ></Enroll>
    <Footer style="margin-top: 100px"></Footer>
  </div>
</template>

<script>
import { Loading } from 'element-ui'

import Title from '@/components/hometitle/hometitle.vue'
import Footer from '@/components/foot/foot.vue'
import { jobOrgDetail } from '@/api/index/index'
import { rotationChart } from '@/api/home/home'
import Enroll from '@/components/enroll/enroll.vue'
import {
  jcCourseDetail,
  jcCourseItemPage,
  applyPage,
  jcCourseApplyAdd,
} from '@/api/course/course'
import { createOrder, aliPcPay } from '@/api/pay/pay'
import { getLoginUser } from '@/api/login/login'
import pay from '@/components/pay/pay'
export default {
  name: '',
  data() {
    return {
      rotationChartList: [], // 轮播图
      activeKey: '1',
      openChapter: 0,
      imgList: [
        '../../img/online2.jpg',
        '../../img/online3.jpg',
        '../../img/online4.jpg',
        '../../img/online5.jpg',
      ],
      imgIntroduction: [
        'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/749968ba-54fa-4638-88af-691a49b919ce.png',
        'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/3678f05f-237d-466b-8d0d-928a7d1b18ed.png',
        'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/20aa1c8e-8aff-4288-821e-9c6595e84dd8.png',
        'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/0231de20-d79a-431b-a0cf-bd3e59af2579.png',
      ],
      dataList: [],
      jobOrg: {},
      query: this.$route.query,
      course: {},
      pay: false,
      dialogVisible: false,
      payInfo: {},
      outerVisible: false,
    }
  },
  watch: {
    /** 数据共享门户 打开新窗口*/
    // $route(to, from) {
    //   if (to.path === '/examinationView') {
    //     this.$router.push(from.path) // 当前路由停留在当前页面，选中效果还停留在上一次点击的路由上
    //     let routeData = this.$router.resolve({ path: '/examinationView' })
    //     window.open(routeData.href, '_blank')
    //   }
    // },
  },
  props: {},
  components: {
    Title,
    Footer,
    Enroll,
    pay,
  },
  computed: {},
  created() {
    console.log(this.query.id)
    jcCourseDetail({ id: this.query.id }).then((res) => {
      console.log('课程', res)
      if (res.success) {
        this.course = res.data
        this.course.createTime = this.course.createTime.slice(0, 10)
        var userId = localStorage.getItem('userId')
        console.log(userId)
        if (userId) {
          // this.isApply(userId)

          applyPage({ jobUserId: userId, jobCourseId: this.course.id }).then(
            (res) => {
              console.log(res, '获取报名')
              if (res.success) {
                if (res.data.rows.length === 0) {
                  // 可以报名
                  this.pay = false
                } else {
                  this.pay = true
                }
              }
            }
          )
        }
      }
    })

    jcCourseItemPage({
      courseId: this.query.id,
      pageNo: 1,
      pageSize: 500,
    }).then((res) => {
      console.log('课程大纲', res)
      if (res.success) {
        var data = res.data.rows
        var detailTree = data.filter((e) => e.pid === null)
        detailTree.forEach((d) => {
          d.data = data.filter((e) => e.pid === d.id)
        })
        console.log(detailTree)
        this.dataList = detailTree
      }
    })
  },
  mounted() {},
  methods: {
    openChapters(item, index) {
      if (this.openChapter == index) {
        this.openChapter = -1
      } else {
        this.openChapter = index
      }
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    learn(id) {
      this.$router.push({
        path: '/learn',
        query: {
          id: id,
        },
      })
    },
    isApply(userId) {
      //开启加载loading....
      var loading = Loading.service({
        lock: true,
        text: '努力加载中....',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      applyPage({ jobUserId: userId, jobCourseId: this.course.id }).then(
        (res) => {
          console.log(res, '获取报名')
          if (res.success) {
            if (res.data.rows.length === 0) {
              // 可以报名
              this.dialogVisible = true
              this.payInfo = {
                amount: (
                  this.course.originalPrice -
                  (this.course.originalPrice * this.course.discountRate) / 100
                ).toFixed(2),
                jobUserId: userId,
                num: 1,
                payMethod: '2', //  1 wx ,2 ali
                orderType: '3', // 1 会员 ,2 权益 3 课程
                userType: '1', // 1 个人 ,2 企业
                productName: this.course.title,
                jobCourseId: this.course.id,
              }
              loading.close()
              // createOrder({
              //       "amount": 0.01,
              //       "jobUserId": userId,
              //       "payMethod": "1", // 1 ali , 2 wx
              //       "orderType": "3", // 1 会员 ,2 权益 3 课程
              //       "userType": "1", // 1 个人 ,2 企业
              //       "productName":this.course.title
              // }).then(res => {
              //   console.log(res);
              //   var order = res.data
              //   order.outTradeNo = order.orderNo

              //   jcCourseApplyAdd({ jobUserId: userId, jobCourseId: this.course.id,outTradeNo:order.orderNo }).then(res => {
              //     console.log(res, '报名')
              //     if (res.success) {
              //       // 支付
              //       aliPcPay(order).then(res => {
              //         console.log(res);

              //         loading.close();

              //         const newWindow = window.open('', '_blank');
              //         newWindow.document.write(res);
              //         newWindow.focus();
              //       })

              //     }
              //   })
              // })
            } else {
              this.pay = true
            }
          }
        }
      )
    },
    showApply(userId) {
      this.dialogVisible = false
      //开启加载loading....
      var loading = Loading.service({
        lock: true,
        text: '努力加载中....',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      applyPage({ jobUserId: userId, jobCourseId: this.course.id }).then(
        (res) => {
          if (res.success) {
            if (res.data.rows.length === 0) {
              this.$confirm('支付失败,可前往订单页继续支付', '', {
                confirmButtonText: '继续支付',
                showCancelButton: false,
                type: 'error'
              }).then(() => {
                this.$router.push('/personalCenter/myOrder')
                }).catch(() => {});
              loading.close()
            } else {
              this.pay = true
              this.enrollClick()
              loading.close()
            }
          }
        }
      )
    },
    enrollClick(data) {
      this.outerVisible = true
      this.$nextTick(() => {
        this.$refs.dialog.init(data)
      })
    },
    registrationDetails(type) {
      if (!localStorage.getItem('userId')) {
         this.$confirm('请先登录!', '', {
          confirmButtonText: '登录',
          showCancelButton: false,
          type: 'warning'
        }).then(() => {
         this.$router.push('/login')
        }).catch(() => {});
        return
      }
      getLoginUser().then((res) => {
        console.log(res, '获取用户')
        if (res.success) {
          localStorage.setItem('userId', res.data.id)
          var userId = res.data.id
          let num = (this.course.originalPrice - (this.course.originalPrice * this.course.discountRate) / 100).toFixed(2)
          if(+num){
               this.isApply(userId)
          }else{
              jcCourseApplyAdd({ jobUserId: userId, jobCourseId: this.course.id,outTradeNo: -1}).then(res => {
                  console.log(res, '报名')
                  if (res.success) {
                    this.$router.go(0)
                  }
                })
          }
        }
      })

      if (type == 1) {
        // window.open('/examinationView', '_blank')
        /*
        this.$router.push({
          path: '/examinationView',
          query: {
            type: 1,
          },
        })
         */
      }
    },
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.c {
  width: 1320px;
  margin: 0 auto;
}

p {
  margin: 0;
  padding: 0;
}

.pageMain {
  background-color: rgba(235, 236, 237, 1);
  // height: 400px;
  // border: 1px solid red;
}
.topBgc {
  position: relative;
  margin-top: 80px;
  .head-box {
    height: 300px;
    background: url('~@/assets/onlineLearning/heard3.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
.content-box {
  position: relative;
  margin-top: 80px;
  padding-top: 65px;
  background: url('~@/assets/onlineLearning/heard3.png');
  background-repeat: no-repeat;
  background-size: 100% 300px;
  min-height: 400px;
}

::v-deep .el-breadcrumb__item {
  color: rgba(153, 153, 153, 1) !important;
}
.top-content {
  // border: 1px solid red;
  padding-bottom: 80px;
  // top: 65px;
  width: 1200px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: white;
  min-height: 200px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);

  .breadcrumb-box {
    // padding: 17px 30px;
    padding-top: 17px;
    padding-left: 30px;
  }
  .title-head {
    margin-top: 40px;
    height: 358px;
  }
  .content-head-box {
    height: 47px;
    div:nth-child(1) {
      text-align: center;
      padding-top: 50px;
      padding-bottom: 20px;
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 0px;
      color: rgba(51, 51, 51, 1);
    }
  }
}
.bottom-explain-cent {
  // border: 1px solid red;
  width: 945px;
  // height: 356px;
  margin: 0 auto;
  ul {
    // border: 1px solid red;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 150px;
  }
  li {
    padding: 17px 0;
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    text-align: left;
  }
}
.bottom-explain-cent-fist {
  padding-top: 20px;
  span:nth-child(1) {
    display: inline-block;
    margin-left: 87px;
    margin-right: 24px;
    width: 60px;
    height: 36px;
    /** 文本1 */
    font-size: 24px;
    font-weight: 700;
    color: rgba(194, 194, 194, 1);
    text-align: right;
  }
  /*   span:nth-child(2) {
    display: inline-block;
    margin-right: 15px;
    width: 64px;
    height: 27px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    text-align: left;
  } */
  span:nth-child(2) {
    // margin-right: 15px;
    // border: 1px solid red;
    position: relative;
    top: 7px;
    display: inline-block;
    width: 640px;
    overflow: hidden;
    height: 27px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    text-align: left;
  }
  img {
    width: 14.16px;
    height: 7px;
  }
}

.title-show {
  margin: 0 auto;
  height: 36px;
  width: 200px;
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 22px;
  color: rgba(51, 51, 51, 1);
}

.bottom-explain {
  width: 913px;
  // height: 189px;
  margin: 0 auto;
  margin-bottom: 52px;
  div:nth-child(1) {
    // border: 1px solid red;
    margin: 0 auto;
    height: 36px;
    width: 200px;
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 22px;
    color: rgba(51, 51, 51, 1);
  }
  div:nth-child(2) {
    width: 913px;
    font-size: 16px;
    font-weight: 400;
    // letter-spacing: 0px;
    line-height: 25px;
    color: rgba(102, 102, 102, 1);
    text-align: left;
  }
}
.bottom-box {
  position: relative;
  width: 1200px;
  height: 60px;
  margin-bottom: 41px;
  background: rgba(237, 252, 255, 1);
  .icon-title {
    display: inline-block;
    margin-left: 91px;
    margin-top: 20px;
    width: 260px;
    // border: 1px solid red;
    img {
      width: 20px;
      height: 20px;
    }
    span {
      margin-left: 15px;
      position: relative;
      top: -4px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 0px;
      color: rgba(102, 102, 102, 1);
    }
  }
  .icon-title2 {
    margin-left: 10px;
    display: inline-block;
    margin-top: 20px;
    width: 177px;
    img {
      width: 20px;
      height: 20px;
    }
    span {
      margin-left: 15px;
      position: relative;
      top: -4px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 0px;
      color: rgba(102, 102, 102, 1);
    }
  }
}

.img-top {
  width: 300px;
  height: 222.7px;
  margin-left: 34px;
  // float: right;
}
.top-explain {
  margin-top: 6px;
  letter-spacing: 0px;
  line-height: 24px;
  width: 757px;
  height: 246px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-align: left;
  vertical-align: top;
  display: -webkit-box;
  overflow: hidden;
}
.top-sign-up {
  margin-left: 145px;
  margin-top: 39px;
  width: 160px;
  height: 21px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 0px;
  color: rgba(153, 153, 153, 1);
  text-align: left;
  vertical-align: top;
}
.rows-price {
  // border: 1px solid red;
  position: relative;
}
.share-star {
  position: absolute;
  top: -6px;
  right: 58px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  .spanPostion {
    margin-left: 5px;
    position: relative;
    top: -3px;
  }
}
.price {
  display: flex;
  .top-price {
    height: 20px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 30px;
    color: rgba(153, 153, 153, 1);
    div:nth-child(1) {
      flex: 6;
    }
    div:nth-child(2) {
      text-align: right;
      flex: 6;
    }

    .iconSize {
      font-size: 20px;
    }
    .spanPostion {
      margin-left: 5px;
      position: relative;
      top: -3px;
    }
  }

  .top-price-button-span {
    height: 59px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    .present-price {
      padding-right: 20px;
    }
    div:nth-child(1) {
      flex: 6;
    }
    div > span:nth-child(1) {
      text-align: left;
      font-size: 24px;
      font-weight: 700;
      color: rgba(250, 73, 73, 1);
    }
    div > span:nth-child(2) {
      text-align: left;
      font-size: 40px;
      font-weight: 700;
      color: rgba(250, 73, 73, 1);
    }
    div:nth-child(2) {
      flex: 6;
    }

    .iconSize {
      width: 16px;
      height: 16px;
    }
  }
}

.button-apply-audition {
  width: 160px;
  height: 40px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(21, 146, 234, 1);
  border: 1px solid rgba(21, 146, 234, 1);
  letter-spacing: 1px;
  position: relative;
  top: 10px;
  right: -70px;
}
.top-price-button {
  margin-top: 10px;
  margin-right: 58px;

  .button-apply {
    float: right;
    position: relative;
    width: 160px;
    height: 40px;
    font-size: 18px;
    font-weight: 400;
    background: rgba(250, 73, 73, 1);
    top: 10px;
    letter-spacing: 1px;
  }
}

::v-deep .el-breadcrumb__inner {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 0px;
}
</style>
